import React, {useEffect, useState} from 'react';
import Header from "../conponents/Header";
import axios from "axios";
import {Divider, Empty, Flex, Input, List, Space} from "antd";
import './hot_word.css'
import {SearchOutlined} from "@ant-design/icons";
import {useViewport} from "../conponents/ViewportProvider";

const titles = {
    'douyin': '抖音',
    'kuaishou': '快手',
    'toutiao': '今日头条',
}

const rankNumber = (i) => (
    <span style={{
        width: '18px',
        lineHeight: '18px',
        fontSize: '12px',
        display: 'inline-block',
        borderRadius: '4px',
        textAlign: 'center',
        backgroundColor: i===1 ? '#f1404b' : i===2 ? '#c56831' : i===3 ? '#b89e2c' : '#d7d7d7ff',
    }}>{i}</span>
)

const getItem = (platform, item, index, showLogo) =>
    <Flex>
        <Space align='start'>
            {
                showLogo ?
                <img style={{width: '20px', height: '20px', verticalAlign: 'top'}} src={`/${platform}.svg`} alt='error'/>
                : null
            }
            {rankNumber(index)}
            <span style={{color: 'rgb(40, 42, 45)', cursor: 'pointer'}} onClick={()=>{
                let url = platform === 'toutiao' ? item.url :
                    platform === 'douyin' ? `https://www.douyin.com/search/${item.hot_word}`
                        : `https://www.kuaishou.com/search/video?searchKey=${item.hot_word}`
                window.open(url, '_blank')
            }}>{item.hot_word}</span>
        </Space>
        <div style={{marginLeft: 'auto', color: '#ced1d3ff'}}>{item.hot_value}</div>
    </Flex>

const HotList = (props) => {
    return (
        <div style={{borderRadius: '12px', border: '1px solid #ccc', flex: '400px'}}>
            <Flex gap='small' justify='center' align='center' style={{padding: '10px 5px 10px 0'}}>
                <img style={{width: '20px', height: '20px', verticalAlign: 'top'}} src={`/${props.platform}.svg`} alt='error'/>
                <span>{titles[props.platform]}</span>
            </Flex>
            <div style={{maxHeight: '300px', overflow: 'auto',}}>
                <List size='small' dataSource={props.list}
                    renderItem={(item, index) => (
                        <List.Item key={index} style={{display: 'block'}}>
                            {getItem(props.platform, item, index+1, false)}
                        </List.Item>
                    )}/>
            </div>
        </div>
    )
}

const HotWord = () => {
    const [data,setData]= useState({})
    const [searchKey, setSearchKey] = useState(null)
    const {width} = useViewport()
    useEffect(()=> {
        (async function fetchData() {
            const res = await axios('/api/v1/hot_word')
            setData(res.data);
        })()
    }, [])
    const handleSearch = (key) => {
        let rst = []
        Object.entries(data).forEach((item) => {
            item[1].forEach((i, index) => {
                if(i.hot_word.includes(key)) rst.push({
                    platform: item[0],
                    item: i,
                    index: index+1,
                })
            })
        })
        return rst
    }
    return (
        <div>
            <Header/>
            <div style={{padding: '30px 32px 0'}}>
                <Flex justify='center'>
                    <Input placeholder="输入关键词" allowClear
                           prefix={<SearchOutlined />}
                           style={{maxWidth: '800px', width: '80%'}}
                           onChange={(v)=> v.target.value.length === 0 ? setSearchKey(null) : setSearchKey(handleSearch(v.target.value))}
                    />
                </Flex>
                <Divider orientation='left' plain>{searchKey !== null ? '搜索结果' : null}</Divider>
                {
                    searchKey === null ? null :
                        searchKey.length === 0 ?
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{height: 60}}
                                description={<span>未查询到关键词</span>}
                            /> :
                            <Flex justify={'center'}>
                                <div style={{ maxWidth: '800px', width: width < 500 ? '100%' : '90%'}}>
                                    <List size='small' dataSource={searchKey}
                                          renderItem={(item, index) => (
                                              <List.Item key={index} style={{display: 'block'}}>
                                                  {getItem(item.platform, item.item, item.index, true)}
                                              </List.Item>
                                          )}/>
                                </div>
                            </Flex>

                }
                { searchKey === null ? null : <Divider/> }
                <Flex wrap='wrap' gap='middle' justify='center'>
                    { Object.entries(data).map((d) =>
                        <HotList platform={d[0]} list={d[1]}/>
                    )}
                </Flex>
            </div>
        </div>
    )
}

export default HotWord;