import {useState} from "react";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {globalState} from "./store";

function getQueryString(name) {
    let reg = RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
    let r = window.location.search.substring(1).match(reg);
    if (r) return decodeURIComponent(r[2])
    return null
}

// todo: 微信端所有页面直接登陆
const WxTest = (props) => {
    const [loginState, setLoginState] = useState(false)
    console.log(getQueryString('code'))
    // todo: 解析jwt判断是否已经登陆
    let token = document.cookie
    // let strings = token.split(".") //截取token，获取载体
    // let userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/"))))); //解析，需要吧‘_’,'-'进行转换否则会无法解析
    if (token) {
        console.log(token)
        // todo: 已经登陆加载uid到全局state
    } else {
        if (/MicroMessenger/.test(window.navigator.userAgent)) {
            let code = getQueryString('code')
            if (code) {
                // todo: 未登陆，后端请求请求数据并存储到前端jwt，如果是第一次登陆相当于注册
            } else {
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe12c72114e8b39ff&redirect_uri=${window.location}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
            }
        }
    }

    const dispatch = useDispatch()
    const info = useSelector((state) => state.global.login)
    return (
        <>
            {props.children}
            <div>{info ? 1 : 2}</div>
            <Button onClick={()=>{
                dispatch(globalState.actions.setLogin(!info))
            }}>change</Button>
        </>
    )
}

export default WxTest;