import {Flex, Image, message, QRCode, Space} from 'antd';
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {globalState} from "../store";

const Login = () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const flag = params.get('flag')
    const [messageApi, contextHolder] = message.useMessage();
    const [value, setValue] = useState()
    const [status, setStatus] = useState('loading')
    const navigate = useNavigate()
    const dispatch = useDispatch();
    let refreshQrcode = () => {
        axios.get('/api/v1/login/qrcode')
            .then((res) => {
                setValue(res.data)
                setStatus('active')
                // 轮询状态
                let getStatus = (n) => {
                    if (n > 10) {
                        setStatus('expired')
                        return
                    }
                    axios.get('/api/v1/login/qrcode/status').then((res) => {
                        dispatch(globalState.actions.setLogin(true))
                        navigate(-1, {replace: true})
                    }).catch(() => {
                        setTimeout(getStatus, 2000, n + 1)
                    })
                }
                getStatus(0)
            }).catch((res) => {
        })
    }
    useEffect(() => {
        if (flag) messageApi.open({type: "warning", content: '登陆过期，请重新登陆'})
        refreshQrcode()
    }, []);
    return (<Flex justify='center' align='center' vertical gap='middle' style={{
            paddingTop: '40px',
        }}>
            {contextHolder}
            <QRCode value={value} status={status} onRefresh={refreshQrcode}/>
            <Flex gap='small'>
                <span>打开</span>
                <Image width={18} src="/icons/wechat.svg" preview={false}/>
                <span>扫一扫，关注公众号后即可登录/注册</span>
            </Flex>
        </Flex>)
}

export default Login;