import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Divider, Flex, Image, Space} from "antd";
import Header from "../conponents/Header";
import {useViewport} from "../conponents/ViewportProvider";
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const Card = (props) => (
        <div style={{
            backgroundColor: 'rgb(246, 246, 247)',
            padding: '24px 24px 10px 24px',
            borderRadius: '12px',
            flex: '300px',
        }}>
                <Title level={5}>{props.title}</Title>
                <Paragraph>{props.content}</Paragraph>
        </div>
)

const Details = () => (
    <Flex wrap='wrap' gap='middle'>
        <Card title='数据服务' content='大数据加持，实时跟踪分析海量媒体数据，为您的创作运营创作方向提供专业的数据跟踪'/>
        <Card title='创作工具' content='AI技术加持，提供专业的创作工具，文案生成、视频字幕处理、无水印视频下载等工具...'/>
        <Card title='媒体素材' content='视频、图片、文案等素材共享下载平台，不再为寻找素材发愁，帮助您更快的产出优质内容'/>
        <Card title='创作灵感' content='实时分析海量数据，为您提供热门词条、热门选题、热门标签，让您的创作获得更多的曝光'/>
    </Flex>
)

const Content = () => {
    const {width} = useViewport();
    const navigator = useNavigate()
    const breakpoint = 900
    const content = <>
            <Title level={2} style={{margin: 0, color: 'rgb(60, 60, 67)'}}>Inspire Source</Title>
            <Title level={2} style={{margin: 0, color: 'rgb(60, 60, 67)'}}>创源-创作运营助手</Title>
            <Paragraph style={{
                fontSize: '20px',
                color: 'rgba(60, 60, 67, 0.78)',
                textAlign:  width < breakpoint ? 'center' : 'start',
            }}>大数据+AI技术加持，为自媒体工作者、运营人员、MCN等提供专业的数据服务、创作工具、媒体素材、创作选题、热门词条...
            </Paragraph>
    </>
    const bp_st = <>
        <Space direction='vertical' size='middle' align='center'>
            <Image width={256} src='/logo.svg' preview={false}/>
            {content}
            <Button shape='round' onClick={()=>navigator('/hot_word')} style={{backgroundColor: '#eeeeeeff'}}>
                进入内测体验
            </Button>
        </Space>
    </>
    const bp_gt = <>
        <Flex vertical={true} gap='small'>
            <Flex justify='space-between' align='center'>
                <Space direction='vertical' style={{maxWidth: '560px'}}>
                    {content}
                </Space>
                <div style={{paddingRight: width >=1000 ? '10%': 0}}>
                    <Image width={width < 1000 ? 200 : 320} src='/logo.svg' preview={false}/>
                </div>
            </Flex>
            <div>
                <Button shape='round' onClick={()=>navigator('/hot_word')} style={{backgroundColor: '#eeeeeeff'}}>
                    进入内测体验
                </Button>
            </div>
        </Flex>
    </>
    return (
        <div style={{padding: `${width < breakpoint ? '10px' : '30px'} 32px 0 32px`}}>
            { width < breakpoint ? bp_st : bp_gt }
            <Divider/>
            <Details/>
        </div>
    )
}

const Footer = () => (
    <Flex gap='small' align='center' vertical={true} style={{color: 'rgba(60, 60, 67, 0.78)'}}>
        <a href='https://beian.miit.gov.cn/' target='_blank' rel="noreferrer" style={{color: 'inherit'}}>豫ICP备2023027663号</a>
        <a href='https://beian.mps.gov.cn/' target='_blank' rel="noreferrer" style={{color: 'inherit'}}>桂公网安备 45010702002092号</a>
        <p style={{textAlign: 'center'}}>Copyright © 2023 InspireSource. All Rights Reserved.</p>
    </Flex>
)

const Home = () => (
    <div>
        <Header/>
        <Content/>
        <Divider/>
        <Footer/>
    </div>
)

export default Home;