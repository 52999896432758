import Header from "../conponents/Header";
import {Empty} from "antd";

const Statistics = () => {
    return (
        <div>
            <Header/>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                <span>
                    工程师赶工中...
                </span>
            }/>
        </div>
    )
}

export default Statistics;