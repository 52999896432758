import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css'
import './index.css';
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Home from "./page/home";
import More from "./page/more";
import Downloader from "./page/video_download";
import {ViewportProvider} from "./conponents/ViewportProvider";
import HotWord from "./page/hot_word";
import Statistics from "./page/statistics";
import WxTest from "./wx_test";
import {Provider, useDispatch, useSelector} from "react-redux";
import {globalState, store} from "./store";
import Login from "./page/login";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById('root'));
const App = () => {
    const login = useSelector((state) => state.global.login);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let checkLogin = () => {
        axios.get('/api/v1/login/status').then((res)=>{
            console.log('status', res.data)
            if(res.data === true) return
            dispatch(globalState.actions.setLogin(false))
            navigate('/login?flag=true')
        }).catch(() => {})
    }
    useEffect(() => {
        axios.get('/api/v1/login/status').then((res)=>{
            dispatch(globalState.actions.setLogin(res.data))
        }).catch(() => {})
    }, []);
    useEffect(() => {
        if(!login) return
        if(login)
            axios.get('/api/v1/user/profile').then((res) => {
                dispatch(globalState.actions.updateProfile(res.data))
            })
        const timer = setInterval(checkLogin, 1000 * 60)
        return () => clearInterval(timer)
    }, [login]);
    return <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/downloader" element={<Downloader/>}/>
        <Route path="/hot_word" element={<HotWord/>}/>
        <Route path="/statistics" element={<Statistics/>}/>
        <Route path="/more" element={<More/>}/>
        <Route path="/wx" element={<WxTest>test</WxTest>}/>
        <Route path="*" element={<Navigate to="/"/>}/>
    </Routes>
}

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <ViewportProvider>
              <BrowserRouter>
                  <App/>
              </BrowserRouter>
          </ViewportProvider>
      </Provider>
  </React.StrictMode>
);