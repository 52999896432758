import {Flex, Image, Space} from "antd";
import React from "react";
import Header from "../conponents/Header";

const More = () => {
    return (
        <div>
            <Header/>
            <Flex vertical={true} align='center' gap='large' style={{padding: '40px'}}>
                <p style={{maxWidth: '560px', fontSize: '1.5em'}}>
                    创源正处于开发初期阶段，如果您有任何对我们的产品有任何建议、使用疑问、bug提交等，
                    欢迎加入官方内测交流群，群里会有我们的产品和技术与大家对接，
                    在群里也可获取第一时间的产品更新细节、问题修复进度等。
                </p>
                <Image width={200} src="/qq.jpeg"/>
            </Flex>
        </div>
    )
}

export default More;