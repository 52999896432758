import {
    CloseOutlined,
    DownloadOutlined,
    FireOutlined,
    HomeOutlined,
    LineChartOutlined, LogoutOutlined, MenuOutlined,
    MoreOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {Affix, Button, Divider, Drawer, Flex, Image, Input, Menu, Popover, Space, Avatar, Dropdown} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useViewport} from "./ViewportProvider";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {globalState} from "../store";

const getItem = (label, key, icon, children, type) => ({key, icon, children, label, type})
const menu = [
    getItem('主页', '/', <HomeOutlined/>, null),
    getItem('热搜聚合', '/hot_word', <FireOutlined/>, null,),
    getItem('视频下载', '/downloader', <DownloadOutlined/>, null,),
    getItem('数据跟踪', '/statistics', <LineChartOutlined/>, null,),
    getItem('更多功能', '/more', <MoreOutlined/>, null,),
]

const Logo = () => {
    const navigator = useNavigate()
    return (
        <Space onClick={() => navigator('/')} style={{cursor: 'pointer'}}>
            <Image width={40} src='/logo.svg' preview={false}/>
            <div style={{fontSize: '20px'}}>创源</div>
        </Space>
    )
}

const DrawerMenu = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Button icon={<MenuOutlined/>} type="text" onClick={() => setOpen(true)}/>
            <Drawer placement='right' closable={false} open={open} onClose={() => setOpen(false)}>
                <Flex justify='space-between'>
                    <Logo/>
                    <Button icon={<CloseOutlined/>} type='text' onClick={() => setOpen(false)} size='large'/>
                </Flex>
                <Divider/>
                <Menu mode="inline" items={menu} defaultSelectedKeys={[location.pathname]} onClick={(e) => {
                    navigate(e.key)
                }}/>
            </Drawer>
        </div>
    )
}

const User = () => {
    const profile = useSelector((state) => state.global.profile)
    const dispatch = useDispatch()
    const content = (
        <Flex justify='flex-end'>
            <Button type="primary" icon={<LogoutOutlined />} size='small' onClick={() => {
                axios.get('/api/v1/user/logout').then(() => {
                    dispatch(globalState.actions.setLogin(false))
                })
            }}>
               注销
            </Button>
        </Flex>
    )
    return (
        <Popover content={content} title={profile.name}>
            <Avatar size={40} src={profile.avatar}/>
        </Popover>
    )
}

const Header = () => {
    const login = useSelector((state) => state.global.login);
    const navigate = useNavigate()
    const {width} = useViewport()
    const location = useLocation();
    const bp_st_800 = (
        <Space>
            <Popover content={<Image width={200} src="/qq.jpeg"/>} title="QQ: 176418198323">
                <Button type="primary">官方交流群</Button>
            </Popover>
            <DrawerMenu/>
        </Space>
    )
    const bp_gt_800 = (
        <>
            {
                width < 1000 ? null :
                    <Space>
                        <Input size="middle" placeholder="输入关键词" prefix={<SearchOutlined/>}/>
                    </Space>
            }
            <Menu onClick={(e) => navigate(e.key)} selectedKeys={[location.pathname]} mode="horizontal" items={menu}/>
            <Popover content={<Image width={200} src="/qq.jpeg" preview={false}/>} title="QQ: 176418198323">
                <Button type="primary">官方交流群</Button>
            </Popover>
            {login ? <User/> : <Button type="primary" onClick={() => navigate('/login')}>登陆</Button>}
        </>
    )
    return (
        <Affix>
            <Flex justify='space-between' align='center' style={{
                height: '64px',
                padding: "12px 32px 12px 32px",
                borderBottom: '1px solid #ccc',
                backgroundColor: '#ffffff',
            }}>
                <Logo/>
                {width < 800 ? bp_st_800 : bp_gt_800}
            </Flex>
        </Affix>
    )
}

export default Header;