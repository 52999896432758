import {Button, Divider, Flex, message, Space, Tooltip, Typography} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    CheckCircleOutlined,
    DownloadOutlined,
    HeartOutlined, MessageOutlined,
    PlayCircleOutlined, SyncOutlined
} from "@ant-design/icons";
import Header from "../conponents/Header";
import {useNavigate} from "react-router-dom";

const { Text } = Typography;

const UrlList = (props) => {
    const [itemState, setItemState] = useState(props.data.map((item, index) => ({checked: false, downloading: false, item: item})))
    useEffect(() => {
        setItemState(props.data.map((item, index) => ({checked: false, downloading: false, item: item})))
    }, [props.data]);
    const downloader = async (item, index) => {
        let d = itemState[index].downloading
        if(d) return
        let is = [...itemState]
        is[index].downloading = true
        setItemState(is)
        let response = await fetch(item.detail.download_url)
        let blob = await response.blob()
        let objectUrl = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = objectUrl
        a.download = item.detail.title.substring(0,5)
        document.body.appendChild(a)
        a.click()
        a.remove()
        is = [...itemState]
        is[index].downloading = false
        setItemState(is)
    }
    return (
        <Flex vertical={true} gap='small'>
            <Flex justify='center' gap='large'>
                <Button type='primary' onClick={()=>{
                    itemState.forEach((state, index) => {
                        if(state.checked) downloader(state.item, index)
                    })
                }}>下载选中项</Button>
                <Button type='primary' onClick={()=>{
                    let is = [...itemState]
                    setItemState( is.map((i, index)=>{
                        i.checked = true
                        return i
                    }))
                }}>全选所有项</Button>
            </Flex>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                gridGap: '20px',
            }}>
                {
                    itemState.map((item, index) => {
                        item = item.item
                        return (
                        <Flex vertical={true} gap='small' style={{borderRadius: '6px', backgroundColor: 'rgb(246, 246, 247)'}}>
                            <Flex justify='space-between'>
                                <img src={item.detail.pic}  alt='error' width={160} height={90} style={{
                                    backgroundColor: '#eeeeeeff',
                                    objectFit: "contain",
                                }}/>
                                <div>
                                    <Space.Compact block direction='vertical'>
                                        <Tooltip title={itemState[index].checked ? '取消勾选' : "选中该项"}>
                                            <Button icon={<CheckCircleOutlined style={{color: itemState[index].checked ? 'lightgreen' : null}}/>} onClick={()=>{
                                                let is = [...itemState]
                                                is[index].checked = !itemState[index].checked
                                                setItemState(is)
                                        }}/></Tooltip>
                                        <Tooltip title={itemState[index].downloading ? '正在下载': "下载视频"}>
                                            <Button icon={itemState[index].downloading ? <SyncOutlined spin style={{color: '#438bffff'}}/> : <DownloadOutlined />} onClick={ ()=>downloader(item, index)}/>
                                        </Tooltip>
                                        <Tooltip title="播放视频"><Button icon={<PlayCircleOutlined />} onClick={()=>{
                                            window.open(item.detail.download_url,"_blank");
                                        }}/></Tooltip>
                                    </Space.Compact>
                                </div>
                            </Flex>
                            <div>
                                <Text ellipsis={{tooltip: item.detail.title}}>{item.detail.title}</Text>
                                <div>
                                    { item.platform === 'douyin' ? <MessageOutlined /> : <PlayCircleOutlined /> }
                                    <span style={{marginLeft: '3px'}}>{item.platform === 'douyin' ? item.statistics.comment : item.statistics.view}</span>
                                    <Divider type="vertical" />
                                    <HeartOutlined /><span style={{marginLeft: '3px'}}>{item.statistics.like}</span>
                                </div>
                            </div>
                        </Flex>
                    )})
                }
            </div>
        </Flex>
    )
}

const Content = () => {
    const [loadingState, setLoadingState] = useState(false)
    const [urlData, setUrlData] = useState('')
    const [urlList, setUrlList] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const navigator = useNavigate()
    return (
        <div style={{
            padding: '40px 40px 0 40px',
        }}>
            {contextHolder}
            <Flex vertical={true} gap='large'>
                <Space>
                    <div>当前支持平台: 抖音、西瓜视频、B站、快手</div>
                    <Button type='primary' onClick={()=>navigator('/more')}>反馈支持更多平台</Button>
                </Space>
                <TextArea rows={6} value={urlData}
                    placeholder="输入视频链接，支持多格式、批量分析、短链接解析、链接提取、多视频下载"
                    onChange={(e)=>{ setUrlData(e.target.value)}}
                />
                <Flex justify='center' gap='large'>
                    <Button type='primary' onClick={()=>{ setUrlData(''); setUrlList([])}}>一键清空</Button>
                    <Button type="primary" loading={loadingState} onClick={()=>{
                        if(urlData.length > 0) {
                            (async function fetchData() {
                                setLoadingState(true)
                                const res = await axios({
                                    method: 'post',
                                    url: '/api/v1/parse_video',
                                    data: urlData.toString(),
                                    headers: {"Content-Type": "text/plain"},
                                })
                                if(res.status === 200) {
                                    if(res.data.status === 0) {
                                        setUrlList(res.data.data)
                                    } else {
                                        messageApi.open({
                                            type: 'error',
                                            content: res.data.data,
                                        })
                                    }
                                }
                                setLoadingState(false)
                            })()
                        }
                    }}>一键解析</Button>
                </Flex>
            </Flex>
            <Divider/>
            { urlList.length > 0 ? <UrlList data={urlList}/> : null }
        </div>
    )
}

const Downloader = () => (
    <div>
        <Header/>
        <Content/>
    </div>
)

export default Downloader;