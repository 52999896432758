import {configureStore, createSlice} from "@reduxjs/toolkit";
import axios from "axios";


const globalState = createSlice({
    name: 'globalState',
    initialState: {
        login: false,
        profile: {avatar: '/headers/header1.svg', name: '微信用户'},
    },
    reducers: {
        setLogin: (state, action) => {
            state.login = action.payload
        },
        updateProfile: (state, action) => {
            state.profile = action.payload
        }
    },
})

const store = configureStore({
    reducer: {
        global: globalState.reducer
    }
})

export {globalState, store};